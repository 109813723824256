export const mapToSelect = (array, label, value) => {
    if (!array) return;

    let newArray = array.map((item) => {
        return {
            label: item[label],
            value: item[value]
        };
    });

    return newArray;
};
