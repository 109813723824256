import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        paddingBottom: `${theme.spacing(12)}px`
    },
    grid: {
        maxWidth: '1165px',
        margin: '0 auto',
        width: '100%'
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(7)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        borderRadius: '25px',

        [theme.breakpoints.up('md')]: {
            '& .MuiGrid-grid-md-6': {
                maxWidth: 'calc(50% - 50px)',
                flexBasis: 'calc(50% - 50px)',
                '& .MuiGrid-grid-md-6': {
                    maxWidth: 'calc(50% - 15px)',
                    flexBasis: 'calc(50% - 15px)'
                }
            }
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4)
        }
    },
    remove: {
        color: 'red',
        textAlign: 'right',
        cursor: 'pointer'
    },
    title: {
        textAlign: 'center',
        paddingTop: `${theme.spacing(8)}px`,
        paddingBottom: `${theme.spacing(2)}px`
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(1)}px`
    },
    label: {
        ...theme.typography.h6,
        fontWeight: 500,
        fontSize: '1.15em',
        padding: `${theme.spacing(2.25)}px 0 ${theme.spacing(1)}px`
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: 1.5,
            fontWeight: '500',
            fontSize: '1.15em'
        }
    },
    customGrid: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    gridItem: {
        flex: '0 0 33%',
        margin: '0 10px 0 -11px',
        [theme.breakpoints.down('sm')]: {
            flex: '1 1 100%',
            margin: 0
        }
    },
    addBar: {
        ...theme.typography.body1,
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: `${theme.spacing(1.5)}px 0`,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& .MuiSvgIcon-root': {
                transform: 'rotate(90deg)'
            }
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1.5),
            transition: 'transform 300ms ease'
        }
    },
    lightbox: {
        marginLeft: 0,
        padding: `${theme.spacing(1.5)}px 0`
    },
    publishButton: {
        background: theme.palette.secondary.light,
        color: theme.palette.text.secondary
    },
    changeButton: {
        background: '#D0BADA',
        color: theme.palette.text.secondary
    },
    deleteButton: {
        background: '#F6D0D0',
        color: theme.palette.text.secondary
    },
    copyButton: {
        background: '#D4ECF7',
        color: theme.palette.text.secondary
    },
    previewButton: {
        background: '#D4ECF7',
        color: theme.palette.text.secondary
    },
    reactionButton: {
        background: '#FFE88B',
        color: theme.palette.text.secondary
    },
    richTextField: {
        marginTop: -theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: -theme.spacing(8.5)
        }
    }
}));
