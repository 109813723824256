import { useReducer } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_FILE } from './graphql';

const useUploadFile = (endPoint) => {
    const [dataObj, setDataObj] = useReducer(
        (state, data) => {
            return {
                ...state,
                ...data
            };
        },
        {
            data: false,
            error: false,
            loading: false
        }
    );

    const [uploadFileMutation] = useMutation(endPoint || UPLOAD_FILE);

    const uploadFile = (data, name, form) => {
        if (data && data.size) {
            setDataObj({ loading: true });
            uploadFileMutation({
                variables: {
                    file: data
                },
                context: {
                    hasUpload: true
                }
            })
                .then((r) => {
                    setDataObj({ loading: false, data: r.data, error: false });
                    if (name && form) {
                        if (r.data && r.data.uploadFile && r.data.uploadFile.asset)
                            form.onFieldChange({ key: name, value: r.data.uploadFile.asset.id || null });
                    }
                })
                .catch((e) => {
                    setDataObj({ loading: false, data: false, error: e });
                });
        }
    };

    return [uploadFile, dataObj];
};

export default useUploadFile;
