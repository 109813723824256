import gql from 'graphql-tag';

export const CREATE_USER = gql`
    mutation ($city: Long!, $email: String!, $firstName: String!, $lastName: String!, $middleName: String) {
        register(
            input: { city: $city, email: $email, firstName: $firstName, lastName: $lastName, middleName: $middleName }
        ) {
            user {
                accountEnabled
                email
            }
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation ($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
            result
        }
    }
`;

export const FINISH_REGISTRATION = gql`
    mutation ($input: FinishRegistrationInput!) {
        finishRegistration(input: $input) {
            user {
                id
            }
        }
    }
`;
