import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import { TableCell, TableRow, makeStyles, Typography } from '@material-ui/core';
import { ArrowRightAlt, DeleteOutline, NotificationsNone } from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.background
    },
    root: {
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
        lineHeight: 0,
        '&:first-child': {
            paddingLeft: 0
        },
        '&:last-child': {
            paddingRight: 0
        }
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        position: 'relative',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        },
        '&:last-child': {
            '& > td': {
                border: 0
            }
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.text.secondaryLight
    },
    title: {
        color: theme.palette.text.secondary
    },
    time: {
        marginRight: theme.spacing(1)
    },
    nameCell: {
        width: '450px'
    },
    actionButton: {
        border: '1px solid ' + theme.palette.text.secondaryLight,
        borderRadius: '50%',
        color: theme.palette.text.secondary,
        background: theme.palette.common.white,
        width: '50px',
        height: '50px',
        padding: '10px',
        marginLeft: '20px',
        cursor: 'pointer',
        transition: 'all 300ms ease',
        '&:hover': {
            border: '1px solid ' + theme.palette.text.secondary,
            background: theme.palette.text.secondary,
            color: theme.palette.common.white
        }
    },
    active: {
        border: '1px solid ' + theme.palette.text.secondary,
        background: theme.palette.text.secondary,
        color: theme.palette.common.white
    }
}));

const AlertRow = (props) => {
    const { alert, onClick, onDelete, onNavigate } = props;
    const classes = useStyles();

    return (
        <StyledTableRow
            // onClick={() => onClick(alert.id)}
            className={classNames(classes.row, { [classes.link]: onClick })}
        >
            <StyledTableCell className={classes.nameCell} align="left">
                <Typography className={classes.title}>{alert.search}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
                <Typography className={classes.center}>{alert.city ? alert.city.name : ''}</Typography>
            </StyledTableCell>
            <StyledTableCell align="left">
                <Typography className={classes.center}>{alert.distance}km</Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
                <div className={classes.center}>
                    <NotificationsNone
                        onClick={() => onClick(alert.id, alert.active)}
                        className={classNames(classes.actionButton, {
                            [classes.active]: alert.active
                        })}
                    />
                    <DeleteOutline onClick={() => onDelete(alert.id)} className={classes.actionButton} />
                    <ArrowRightAlt onClick={() => onNavigate(alert)} className={classes.actionButton} />
                </div>
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default AlertRow;
