import gql from 'graphql-tag';

export const GET_SPECIALTIES = gql`
    query specialtyVacancyTypes {
        specialtyVacancyTypes {
            count
            types {
                id
                description
                explanation
            }
        }
    }
`;

// Used to fetch own complete profile for the profile page, make sure the actual data is displayed here
// (a user might've changed the state via the MijnFLOW portal in the meantime which is why using the
// redux state of the Kansenportaal-portal is not suitable).
export const GET_CURRENT_USER = gql`
    query {
        currentUser {
            id
            careerProfile {
                cv {
                    id
                    name
                    url
                }
                documents {
                    id
                    name
                    url
                }
            }
            email
            emailIsCorporate
            profile {
                id
                biography
                completeness {
                    done
                    name
                }
                dateOfBirth
                educationGrade {
                    id
                    description
                }
                firstName
                middleName
                lastName
                fullName
                profilePublic
                salaryScale {
                    id
                    description
                }
                specialties {
                    id
                    description
                }
                position
                profilePicture {
                    id
                    name
                    thumbnailUrl
                    description
                    url
                }
                city {
                    id
                    name
                }
                educations {
                    id
                    direction
                    endYear
                    institution
                    startYear
                    title
                }
                workExperience {
                    id
                    city {
                        id
                        name
                        province
                    }
                    company
                    endYear
                    startYear
                    position
                }
                skillList {
                    id
                    description
                }
                expertiseList {
                    id
                    description
                }
                region {
                    id
                    description
                }
                workingHoursPerWeek {
                    id
                    value
                }
                preferredPosition
                preferredSalaryScale {
                    id
                    description
                }
                preferredExpertiseList {
                    id
                    description
                }
            }
            corporation {
                id
                domain
                name
            }
        }
    }
`;

export const SAVE_USER = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                id
                profile {
                    completeness {
                        done
                        name
                    }
                }
            }
        }
    }
`;

export const SAVE_PROFILE_PICTURE = gql`
    mutation uploadProfilePicture($file: Upload) {
        uploadProfilePicture(file: $file) {
            asset {
                id
            }
        }
    }
`;

export const REMOVE_PROFILE_PICTURE = gql`
    mutation removeProfilePicture {
        removeProfilePicture {
            user {
                id
            }
        }
    }
`;

export const GET_CITIES = gql`
    query ($filter: CityFilterInput!) {
        cities(pagination: { page: 0, pageSize: 50 }, filter: $filter) {
            cities {
                id
                name
            }
        }
    }
`;

export const GET_REGIONS = gql`
    query Regions {
        regions {
            regions {
                description
                id
            }
        }
    }
`;

export const GET_EDUCATION_GRADES = gql`
    query educationGrades {
        educationGrades(pagination: { page: 0, pageSize: 50 }) {
            educationGrades {
                id
                description
            }
        }
    }
`;

export const GET_EXPERTISES = gql`
    query expertises {
        expertises(pagination: { page: 0, pageSize: 50 }) {
            expertises {
                description
                id
            }
        }
    }
`;

export const GET_SKILLS = gql`
    query skills {
        skills(pagination: { page: 0, pageSize: 50 }) {
            skills {
                description
                id
            }
        }
    }
`;

export const GET_CORPORATIONS = gql`
    query corporations {
        corporations {
            corporations {
                domain
                name
                id
            }
        }
    }
`;

export const GET_SALARY_SCALES = gql`
    query salaryScales {
        salaryScales(pagination: { page: 0, pageSize: 50 }) {
            types {
                id
                description
            }
        }
    }
`;

export const GET_WORKING_HOURS = gql`
    query {
        workingHoursList {
            workingHoursList {
                id
                value
            }
            count
        }
    }
`;

export const UPLOAD_DOCUMENT = gql`
    mutation UploadDocument($file: Upload, $input: UploadDocumentInput) {
        uploadDocument(file: $file, input: $input) {
            asset {
                id
                contentType
                url
                name
            }
        }
    }
`;

export const DELETE_DOCUMENT = gql`
    mutation DeleteDocument($id: Long!) {
        deleteDocument(id: $id) {
            success
        }
    }
`;
