import gql from 'graphql-tag';

// TODO: this query is sorted in the wrong order. Seems to be that the oldest results are returned. Need to be newest.
export const GET_MY_VACANCIES = gql`
    query myVacancies {
        myVacancies(pagination: { page: 0, pageSize: 3 }) {
            vacancies {
                id
                createdOn
                title
                likedBy {
                    id
                }
                responses {
                    id {
                        userId
                        vacancyId
                    }
                }

                publicationPeriod {
                    endDate
                    startDate
                    visibility {
                        description
                    }
                }
            }
            count
        }
    }
`;

export const GET_REGIO_LOGO = gql`
    query user($id: Long!) {
        user(id: $id) {
            corporation {
                id
                name
                region {
                    id
                    description
                    logo {
                        id
                        url
                    }
                }
            }
        }
    }
`;
