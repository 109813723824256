import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import Cookie from 'js-cookie';

import { loginUser, setUserRole, setUserData, logoutUser } from 'store/actions';
import { ROLE_NAMES } from 'const';

const PrivateRoute = (props) => {
    const { component, hrRoute, path, authPath = '/login', ...otherProps } = props;
    const location = useLocation();
    const history = useHistory();
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [userRoles, setUserRoles] = useState();

    // Helper to create a state object to pass along when we push the user to the login screen.
    // This state is parsed by the login to determine whether the user requested a specific page
    // or if the login should continue to the dashboard instead (as a default).
    const createLoginLogoutState = () => {
        const loginParam = otherProps.location.pathname || '';
        return {
            referrer: location.pathname,
            loginParam: loginParam
        };
    };

    useEffect(() => {
        if (token && token !== 'deleted') {
            const decodeToken = jwtDecode(token);
            const date = new Date();
            const expireDate = new Date(decodeToken.exp);

            if (expireDate < date) {
                dispatch(logoutUser);
                history.push('/login');
                return;
            }
            dispatch(setUserData(decodeToken.id));
            setUserRoles(decodeToken.roles);
            const userRoles = decodeToken?.roles?.length ? decodeToken.roles : [ROLE_NAMES.USER];
            const cookieRole = Cookie.get('roleSelect');
            const currentRole = userRoles.find((role) => role === cookieRole) || userRoles[0] || 0;
            if (!cookieRole) {
                Cookie.set('roleSelect', currentRole, { expires: 365 });
            }

            dispatch(setUserRole(currentRole));
            setLoader(false);
        } else {
            history.push('/login', createLoginLogoutState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    if (loader) return '';
    if (token && token !== 'deleted') {
        if (hrRoute) {
            let isHrUser = userRoles.some((item) => item === ROLE_NAMES.HR_USER);
            if (!isHrUser) {
                return (
                    <Redirect
                        to={{
                            pathname: '/unauthorized',
                            state: { referrer: location.pathname }
                        }}
                    />
                );
            }
        }
        return <Route {...otherProps} component={component} />;
    } else {
        dispatch(loginUser(false));
        return (
            <Redirect
                to={{
                    pathname: location.pathname === '/logout' ? '/logout' : authPath,
                    state: createLoginLogoutState()
                }}
            />
        );
    }
};

export default PrivateRoute;
