import React from 'react';
import Lottie from 'react-lottie-player';
import data from './loader-animation';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto'
    }
}));

const Loader = (props) => {
    const { height = 60, width = 60, ...otherProps } = props;
    const classes = useStyles(props);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: data,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return <Lottie config={defaultOptions} height={height} className={classes.root} width={width} {...otherProps} />;
};

export default Loader;
