import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import translationEng from './en.json';
import translationNl from './nl.json';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEng
            },
            nl: {
                translation: translationNl
            }
        },
        lng: 'nl',
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['h1', 'h3', 'h2', 'ul', 'ol', 'li', 'br', 'strong', 'i', 'p']
        }
    });

export default i18n;
