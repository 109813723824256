import React from 'react';
import CustomIcon from './CustomIcon';

const Mp4File = (props) => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801' }}>
        <path d="M402.058 439.257l-15.915 25.977v.358h26.693v-26.335c0-7.13.369-14.444.918-22.128h-.728c-3.838 7.683-6.95 14.629-10.968 22.128z" />
        <path d="M475.095 131.992c-.032-2.526-.844-5.021-2.568-6.993L366.324 3.694c-.021-.034-.053-.045-.084-.076-.633-.707-1.36-1.29-2.141-1.804-.232-.15-.465-.285-.707-.422-.686-.366-1.393-.67-2.131-.892-.2-.058-.379-.14-.59-.192C359.87.114 359.037 0 358.203 0H97.2C85.292 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.908 0 21.601-9.688 21.601-21.601V133.202c-.001-.406-.064-.804-.106-1.21zM213.11 514.783l-1.825-47.188c-.562-14.797-1.097-32.731-1.097-50.651h-.551c-3.839 15.731-8.962 33.292-13.722 47.741l-14.995 48.094h-21.755L146 465.044c-4.029-14.449-8.224-32.01-11.156-48.1h-.369c-.728 16.638-1.274 35.665-2.191 51.021l-2.183 46.818h-25.795l7.868-123.251h37.125l12.063 41.144c3.839 14.27 7.681 29.626 10.434 44.075h.551c3.462-14.271 7.678-30.544 11.696-44.255l13.17-40.964h36.384l6.773 123.251h-27.26zm125.835-56.511c-9.503 8.965-23.583 12.983-40.047 12.983-3.66 0-6.95-.18-9.503-.543v44.064h-27.619v-121.61c8.596-1.461 20.658-2.568 37.676-2.568 17.196 0 29.431 3.296 37.679 9.883 7.857 6.206 13.162 16.453 13.162 28.523-.01 12.088-4.039 22.323-11.348 29.268zm114.496 28.171h-14.27v28.34h-26.336v-28.34h-52.66v-18.109l44.982-72.414h34.014v69.667h14.27v20.856zM97.2 366.752V21.601h250.203v110.515c0 5.961 4.831 10.8 10.8 10.8H453.6l.011 223.836H97.2z" />
        <path d="M300.902 411.645c-5.664 0-9.503.549-11.507 1.087v36.397c2.362.548 5.295.738 9.323.738 14.809 0 23.958-7.505 23.958-20.129-.005-11.322-7.873-18.093-21.774-18.093zM188.821 276.281h151.907V164.953H188.821v111.328zm54.622-88.863c0-.588.345-1.149.875-1.402.551-.271 1.192-.2 1.669.171l51.993 33.188c.369.314.58.763.58 1.231 0 .475-.211.929-.58 1.237l-51.993 33.205c-.287.221-.622.34-.973.34l-.696-.161c-.53-.25-.875-.81-.875-1.4v-66.409zM188.821 278.016v-1.734h-5.508v27.189h163.5v-27.189h-6.085v1.734H188.821zm154.522 1.74v20.24H186.796v-20.24h156.547zM183.312 164.953h5.508v-1.737h151.907v1.737h6.085v-27.187h-163.5v27.187zm3.484-23.709h156.547v20.231H186.796v-20.231z" />
        <path d="M192.884 146.054h23.267v9.855h-23.267zM222.684 146.054h23.269v9.855h-23.269zM252.26 146.054h23.266v9.855H252.26zM282.055 146.054h23.267v9.855h-23.267zM312.82 146.433h23.267v9.859H312.82zM193.163 284.623h23.28v9.861h-23.28zM222.972 284.623h23.269v9.861h-23.269zM252.548 284.623h23.269v9.861h-23.269zM282.351 284.623h23.267v9.861h-23.267zM313.105 285.009h23.266v9.861h-23.266z" />
    </CustomIcon>
);

export default Mp4File;
