import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { Room, AccessTime } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Button, RichTextContainer, Image, FavoriteButton } from 'shared';
import { ROLE_NAMES } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1400px',
        width: '100%',
        margin: '0 auto',
        padding: `${theme.spacing(4)}px ${theme.spacing(0)}px`,
        borderTop: `1px solid ${theme.palette.borderColor.main}`,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'row wrap'
        }
    },
    content: {
        width: '100%',
        flex: '1 1 450px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '550px',
            paddingRight: theme.spacing(6)
        }
    },
    title: {
        ...theme.typography.h6,
        fontSize: '22px',
        paddingBottom: theme.spacing(1),
        textDecoration: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px'
        }
    },
    infoBar: {
        display: 'flex',
        flexWrap: 'wrap',
        opacity: 0.6,
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column nowrap'
        }
    },
    infoItem: {
        marginRight: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 0.5)
        }
    },
    infoItemPlace: {
        flex: 1,
        wordBreak: 'break-word',
        hyphens: 'auto',
        lineHeight: '1.2',
        marginRight: 0
    },
    introductionWrap: {
        overflow: 'hidden'
    },
    introduction: {
        color: theme.palette.text.normal,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        marginBottom: theme.spacing(-1),
        wordBreak: 'break-word',
        maxHeight: '300px'
    },
    links: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 1 100%',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        [theme.breakpoints.up(768)]: {
            marginTop: 0,
            width: '230px',
            flex: '0 0 230px'
        }
    },
    button: {
        height: '60px',
        alignSelf: 'flex-end',
        background: theme.palette.secondary.light,
        '& span': {
            margin: '0'
        }
    },
    favorite: {
        height: '60px',
        alignSelf: 'flex-end'
    },
    imageWrap: {
        flex: '0 0 100%',
        height: 'auto',
        marginBottom: theme.spacing(3)
    },
    image: {
        [theme.breakpoints.up(840)]: {
            width: '230px',
            height: '145px'
        }
    },
    time: {
        minWidth: '130px'
    }
}));

const VacancyItem = (props) => {
    const {
        title,
        path,
        type,
        place,
        corporation,
        hours,
        introduction,
        id,
        format = true,
        liked = false,
        showFavorite,
        contactPerson = {},
        originURL,
        crawled
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const isHrUser = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.HR_USER));
    const isCareerAdvisor = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.CAREER_ADVISOR));
    const isDiAdvisor = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.DI_ADVISOR));
    const [, setExternalVacancy] = useState(false);

    useEffect(() => {
        if (
            contactPerson.id &&
            (originURL ||
                crawled ||
                parseFloat(contactPerson.id) === parseFloat(process.env.REACT_APP_EXTERNAL_CONTACT_ID))
        ) {
            setExternalVacancy(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <span className={classes.content}>
                {title && (
                    <Link to={path} className={classes.title}>
                        {title}
                    </Link>
                )}
                <span className={classes.infoBar}>
                    {type && (
                        <Typography className={classes.infoItem} variant="body1">
                            {type}
                        </Typography>
                    )}
                    {hours && (
                        <Typography className={classNames(classes.infoItem, classes.time)} variant="body1">
                            <AccessTime /> {hours}
                        </Typography>
                    )}
                    {place && (
                        <Typography className={classNames(classes.infoItem, classes.infoItemPlace)} variant="body1">
                            <Room /> {place}
                        </Typography>
                    )}
                </span>
                <div className={classes.introductionWrap}>
                    {introduction && !crawled && (
                        <RichTextContainer
                            format={format}
                            className={classes.introduction}
                            content={introduction}
                            parseText={true}
                        />
                    )}
                </div>
            </span>
            <span className={classes.links}>
                {corporation && corporation.logo && corporation.logo.url !== 'Not saved yet' && (
                    <div className={classes.imageWrap}>
                        <Image src={corporation.logo.url} classes={{ root: classes.image }} alt={corporation.name} />
                    </div>
                )}
                {path && (
                    <Button
                        component={Link}
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        label={t('vacancyItem.button')}
                        to={path}
                    />
                )}
                {showFavorite && !isHrUser && !isCareerAdvisor && !isDiAdvisor && (
                    <FavoriteButton className={classes.favorite} id={id} liked={liked} />
                )}
            </span>
        </div>
    );
};

export default VacancyItem;
